@import "../../default/styles/custom-variables";

//
// Custom variables

$input-color-disabled: map_get($lfi-palette, jet);
// todo - figure out what is going on here and simplify if possible
$store-logo-width: rem-calc(176);
$logo-width: auto;
$logo-height: rem-calc(27);

// buttons
$default-button-font-family: $header-font-family;
$default-button-font-weight: $font-weight__bold;
$default-button-line-height: rem-calc(18);
$default-button-padding: rem-calc(16 30 14 30);
$default-button-light-color: map-get($lacrosse-palette, gold);
$default-button-light-text-color: $white;
$input-button-font-adjusted: $header-font-family;

$yotpo-score-font: $header-font-family;

$product-indicator-font-family: $body-font-bold;

$quick-add-button-padding: 10px 10px 8px;
