@import "../../../../default/styles/components/pdp/page";

.catalog-product-view {
	.page-title-wrapper {
		@include breakpoint(small only) {
			margin-bottom: rem-calc(24);
		}
	}

	.page-title {
		font-family: $body-font-bold;
		text-transform: none;
	}

	.subtitle {
		margin-bottom: 0;
	}
}
