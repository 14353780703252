$header-link-font-size: 16px;
$logo-width: 135px;

@import "../../../default/styles/components/header";

header.header-container {
	align-items: center;
	background: $dark-gray;
	display: flex;
	max-height: rem-calc(69);
	min-height: rem-calc(60);

	@media (max-width: #{$screen__m}), (orientation: landscape) {
		max-height: rem-calc(60);
	}

	.header-wrapper {
		width: 100%;
	}

	.header.content {
		align-items: center;
		margin: 0 auto;

		[id="open-top-nav"] {
			@include xy-cell(1 of 3);
		}
	}

	// only visible on medium+
	.nav-wrapper {
		@include xy-cell(6, false);
	}
}

.header-wrapper {
	.links {

		[role="context"] {
			line-height: rem-calc(24);
		}
		a {
			color: $white;
			padding: rem-calc(0 6);

			@include breakpoint(large) {
				font-size: rem-calc(21);
				padding: rem-calc(0 12);
			}
		}
	}

	.logo {
		align-items: center;
		display: flex;

		> * {
			height: 100%;
			max-width: #{$logo-width};
			width: 100%;
		}
	}

	[data-summary-count]:not([data-summary-count="0"])::after {
		color: $dark-gray;
	}
}

.navigation {
	width: 100%;

	a {
		border-bottom: rem-calc(2) solid transparent;
		color: $white;
		display: block;
		font-family: $header-font-family;
		font-weight: $global-weight-bold;
		letter-spacing: 0.08rem;
		padding-bottom: rem-calc(3);
		padding-top: rem-calc(5);
		text-rendering: optimizeLegibility;
		text-transform: uppercase;

		&:hover {
			border-color: currentColor;
		}
	}

	li {
		height: 100%;

		&:not(:last-of-type) {
			margin-right: rem-calc(10);

			@include breakpoint(750px) {
				margin-right: 4.5%;
			}
		}

		// .has-active means a child category is currently active
		&.has-active,
		&.active {
			a {
				border-bottom: rem-calc(2) solid currentColor;
				color: $primary-color;
			}
		}
	}

	ul {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
	}
}
