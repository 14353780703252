@import "../../../default/styles/components/product-borderfree-restriction";

.bfx-disable-element-container {
	@include breakpoint(medium) {
		background: $body-background-alt;

		.restrictionMessage {
			margin: $grid-container-padding;
		}
	}
}
