@import "../../default/styles/components/plp/_page";

.catalog-category-view {
	.page-title-wrapper {
		display: block;
	}

	// Most Lacrosse pages have banners which include the category title
	.header-banner ~ .page-main .page-title-wrapper {
		@extend .show-for-sr;
	}

	&.page-with-filter {
		.category-view .sub-cats {
			display: none;
		}
	}

	&:not(.page-with-filter) {
		.page-title-wrapper {
			display: none;
		}

		.page-main {
			margin-top: 0;
		}
	}
}

// todo - clean up nesting overload
.catalog-category-view {
	.category-view {
		@at-root {
			.sub-cats {
				background: map-get($lfi-palette, snow);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: {
					top: rem-calc(18);
					bottom: rem-calc(15);
				}

				ul.menu {
					li.anchor-category {
						margin: rem-calc(0 1.5);

						&:hover {
							opacity: 0.6;
						}
					}

					> li {
						text-align: center;

						&.sub-cats-group {
							display: block;

							@include breakpoint(medium down) {
								margin: rem-calc(9 0);
							}

							@include breakpoint(medium) {
								display: inline-block;
								margin-right: rem-calc(15);

								&:last-of-type {
									margin-right: 0;
								}
							}

							> li {
								&:not(:last-of-type) {
									margin-right: rem-calc(6);
								}
							}

							h2 {
								@include headline-strike();
								font-size: rem-calc(18);
								letter-spacing: rem-calc(0.75);
								margin-bottom: rem-calc(6);
								text-transform: uppercase;
							}

							hr {
								display: none;
							}

							ul {
								display: flex;
								justify-content: center;
								margin-left: 0;
							}
						}

						a {
							color: $body-font-color;
						}

						img {
							margin-bottom: rem-calc(6);
							height: auto;
							width: rem-calc(42);
						}

						.child-category-title {
							display: inline-block;
							font-size: rem-calc(12);
							font-weight: $font-weight__semibold;
							margin: rem-calc(6 0 0 0);
							text-transform: uppercase;
						}
					}

					// Stack sub-cat groups on Mobile
					&.groups {
						flex-direction: column;
						padding: {
							top: rem-calc(3);
							bottom: rem-calc(1.5);
						}

						@include breakpoint(medium) {
							flex-direction: row;
						}
					}
				}
			}
		}
	}
}
