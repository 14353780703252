@import "../../default/styles/components/plp/_family-page";

// Product Family Grid
.feature-grid {
	max-width: none;

	.intro {
		@include small-centered($grid-container);
		margin-bottom: rem-calc(90);
		margin-top: rem-calc(90);

		.play-video {
			margin-top: rem-calc(30);
		}
	}

	.feature {
		@include xy-grid();
		border-bottom: rem-calc(3.3) solid $white;
		padding-left: 0;
		padding-right: 0;

		@include breakpoint(medium) {
			&:nth-of-type(odd) {
				.copy {
					order: 1;
				}
				.image {
					order: 2;
				}
			}
		}

		.image {
			padding-bottom: 100%;
			background-size: cover;
			background-position: center center;

			@include breakpoint(medium) {
				padding-bottom: 50%;
			}
		}
	}

	.copy {
		background: map-get($lfi-palette, jet);
		color: map-get($lfi-palette, latte);
		padding: rem-calc(60 30);

		h2 {
			@include scaled-header($min: 30px);
			line-height: 1;
			margin-bottom: 0;
		}

		p {
			opacity: 0.75;
		}

		@include breakpoint(medium) {
			padding: 6%;

			h2 {
				font-weight: $global-weight-bold;
			}
		}

		&.small-centered {
			@include small-centered(870px);

			hr {
				margin: rem-calc(30) auto;
			}
		}

		&.medium-8 {
			text-align: center;
			background-color: transparent;
		}
	}

	.alt {
		background: map-get($lfi-palette, latte);
		color: map-get($lfi-palette, jet);

		hr {
			border-color: map-get($lfi-palette, jet);
		}
	}

	hr {
		border-color: map-get($lfi-palette, latte);
		height: rem-calc(3);
		margin: rem-calc(30 0);
		opacity: 0.45;
		width: rem-calc(45);
	}
}
