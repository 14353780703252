// imports Foundation defaults
@import "foundation-sites/scss/foundation";
// imports Foundation utility classes
@import "foundation-sites/scss/util/util";

// imports project globals
@import "globals/colors";
@import "globals/fonts";

///
// Colors
//
$foundation-palette: (
	primary: map-get($lacrosse-palette, gold),
	secondary: map-get($lacrosse-palette, light-gray),
	tertiary: map-get($lfi-palette, jet),
	success: map-get($lfi-palette, evergreen),
	warning: map-get($lfi-palette, cherry),
	alert: map-get($lfi-palette, cherry)
);

// Creates $*-color vars for $foundation-palette defaults
@include add-foundation-colors;

$anchor-color: map-get($lfi-palette, metal);
$anchor-color-hover: darken($anchor-color, 20%);
$breadcrumbs-item-color-current: $body-medium-font-color;
$breadcrumbs-item-color: $body-medium-font-color;
$breadcrumbs-item-separator-color: map_get($lacrosse-palette, perfect-gray);
$dark-gray: map-get($lfi-palette, jet);
$light-gray: map-get($lacrosse-palette, light-gray);
$medium-gray: map-get($lfi-palette, stone);
$discount-price-color: map-get($lfi-palette, metal);

///
// Layout
//
$global-letter-spacing: rem-calc(0.5);
$global-menu-padding: rem-calc(3 15);
$global-padding: rem-calc(15);
$global-radius: 3px;
$header-container-height: rem-calc(60);

///
// Typography
//
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 2px solid $medium-gray;
$defnlist-term-margin-bottom: 0.3rem;
$global-weight-bold: $font-weight__bolder;
$global-weight-normal: $font-weight__regular;
$header-font-weight: $font-weight__bolder;
$header-letter-spacing: rem-calc(0.5);
// todo - headers sizes should get smaller consistently
$header-styles: (
	small:
		(
			"h1": ("font-size": 30),
			"h2": ("font-size": 26),
			"h3": ("font-size": 23),
			"h4": ("font-size": 20),
			"h5": ("font-size": 18),
			"h6": ("font-size": 16)
		),
	medium:
		(
			"h1": ("font-size": 36),
			"h2": ("font-size": 28),
			"h3": ("font-size": 25),
			"h4": ("font-size": 22),
			"h5": ("font-size": 18),
			"h6": ("font-size": 16)
		)
);
$subheader-color: map-get($lfi-palette, jet);

///
// Buttons
//
$button-font-weight: $global-weight-bold;
$button-letter-spacing: rem-calc(0.5);
$button-padding: 12px 27px 11px;

///
// Forms
//
$fieldset-border: 2px solid $light-gray;
$form-label-font-weight: $font-weight__regular;
$input-border: 2px solid $light-gray;
$input-border-focus: 2px solid $medium-gray;
$input-font-size: rem-calc(20);
$select-triangle-color: $base-color;
$select-radius: $global-radius;

///
// Breadcrumbs
//
$breadcrumbs-margin: 0;

// todo - fix Foundating setting import
$button-palette: $foundation-palette;

// navigation typography
$menu-desktop-font-size: 16px;
$menu-desktop-heading-size: 20px;
$menu-mobile-heading-font-size: 22px;
$menu-mobile-parent-font-size: 22px;
$menu-mobile-font-size: 16px;
