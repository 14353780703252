@import "../../../../default/styles/components/pdp/details";

.product.media {
	@include breakpoint($screen__m) {
		padding-top: 3rem;
	}
}

.product.attribute.description {
	h2 {
		@extend .h3; // todo - merchandising task to make these h3s
	}
}
