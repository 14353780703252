@import "../../../default/styles/globals/colors";

/// Brand Palette
$lacrosse-palette: (
	dark-gold: #a7800a,
	gold: #d39e04,
	cherry: map-get($lfi-palette, cherry),
	grit: #585650,
	aluminum: #908b84,
	light-gray: #e7e7e7,
	perfect-gray: #aaaaaa,
	ursa-orange: #f0895b
) !default;

$accent-color: map-get($lacrosse-palette, gold);
$base-color: map_get($lfi-palette, jet);
$body-background-inverted: map_get($lacrosse-palette, jet);
$body-medium-font-color: map-get($lfi-palette, metal);
$dark-primary-color: map-get($lacrosse-palette, dark-gold);
$primary-hover: map-get($lacrosse-palette, grit);
$body-inverted-font-color: map-get($lfi-palette, cream);
$body-inverted-font-color-hover: map-get($lfi-palette, cream-hover);
