@import "../../../default/styles/helpers/buttons";

@mixin button-primary {
	@include button-core;
	@include button-typography;
	@include button-appearance($button-color: map_get($lfi-palette, jet), $active-color: $primary-hover);
}

@mixin button-oval {
	@include button-primary-light;
	@include button-oval-adjustment;
}

@mixin button-secondary {
	@include button-core;
	@include button-typography;
	@include button-appearance(
		$button-color: map_get($lfi-palette, light-gray),
		$text-color: map_get($lfi-palette, jet)
	);
}

@mixin button-primary-light {
	// @note - Lacrosse primary light button has been retired for accessibility reasons.
	@include button-secondary;
}

@mixin button-type-hollow($color: false, $inverted: false) {
	@if ($color == false) {
		$color: $white;
	}

	$button-active-text-color: map-get($lfi-palette, jet);
	@if ($inverted) {
		$button-active-text-color: $white;
	}

	@include button-core;
	@include button-typography;
	@include button-appearance(
		$active-color: $color,
		$active-text-color: $button-active-text-color,
		$button-color: transparent,
		$border-color: $color,
		$padding: rem-calc(15 30 13 30),
		$text-color: $color,
		$has-border: true
	);
}

@mixin button-input-combined {
	@include button-core;
	@include button-typography($line-height: rem-calc(21));

	@include button-appearance(
		$button-color: map_get($lfi-palette, light-gray),
		$text-color: map_get($lfi-palette, jet)
	);

	// override Foundation :(
	border-radius: rem-calc(0 5 5 0) !important;
	padding: 0 rem-calc(32) !important;
}

@mixin button-hollow-primary() {
	@include button-core;
	@include button-typography;

	@include button-appearance(
		$active-color: map-get($lfi-palette, jet),
		$active-text-color: $white,
		$border-color: map-get($lfi-palette, jet),
		$button-color: transparent,
		$has-border: true,
		$text-color: map-get($lfi-palette, jet),
		$padding: 14px 28px 12px
	);
}
