@import "../../default/styles/components/customer/popup_elements";

.customer-popup-form {
	// these headings are the same size on lacrosse designs :(
	h3,
	h4 {
		font-size: rem-calc(22);
	}

	.newsletter label {
		// override complex form styles
		font-size: rem-calc(14) !important;
	}
}
