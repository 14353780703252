@import "../../../../default/styles/components/pdp/minicart";

.minicart-item h5 {
	font-size: rem-calc(18);

	@include media-breakpoint-up(md) {
		font-size: rem-calc(22);
	}
}

.minicart-actions {
	.continue-shopping {
		color: map-get($lfi-palette, jet);
	}
}
