@import "../../default/styles/components/pdp/_upsell-block.scss";

.block-upsell {
	.price-box .price {
		color: map_get($lacrosse-palette, metal);
	}

	p {
		color: map_get($lfi-palette, metal);
	}

	.product-image-container {
		width: rem-calc(232);

		img {
			width: rem-calc(232);
		}
	}
}
