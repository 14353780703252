@import "../../default/styles/components/customer/popup_forms";

.customer-popup-register-cms-desktop {
	// Special sized h1
	h1 {
		font-size: rem-calc(36);
	}

	li {
		font-family: $body-font-bold;
	}
}
