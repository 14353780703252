.ursa-slider-container {
	background: map-get($lfi-palette, snow);
}

.ursa-slider {
	.slick-track {
		margin: rem-calc(40 0);

		@include media-breakpoint-up(sm) {
			margin: rem-calc(60 0 40 0);
		}

		@include media-breakpoint-up(lg) {
			margin: rem-calc(130 0 100 0);
		}
	}

	.slick-dots {
		@include slick-dot-dashes;
		bottom: rem-calc(30);

		@include media-breakpoint-up(lg) {
			bottom: rem-calc(80);
		}
	}
}

.ursa-slide {
	line-height: 1;
	padding: rem-calc(0 7);

	@include media-breakpoint-up(sm) {
		padding: rem-calc(0 10);
	}

	@include media-breakpoint-up(lg) {
		padding: rem-calc(0 20);
	}

	.container {
		background: $white;
		padding: rem-calc(14);

		@include media-breakpoint-up(sm) {
			padding: rem-calc(20);
		}

		@include media-breakpoint-up(lg) {
			padding: rem-calc(40);
		}
	}

	.wrapper {
		position: relative;
	}

	.tape,
	.label {
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
	}

	.tape {
		max-width: rem-calc(190);
		top: rem-calc(-35);
		width: 15vw;

		@include media-breakpoint-up(md) {
			top: rem-calc(-60);
		}

		@include media-breakpoint-up(lg) {
			top: rem-calc(-105);
		}
	}

	.label {
		bottom: rem-calc(-16);
		max-width: rem-calc(390);
		width: 31vw;

		@include media-breakpoint-up(sm) {
			bottom: rem-calc(-20);
		}

		@include media-breakpoint-up(md) {
			bottom: rem-calc(-24);
		}

		@include media-breakpoint-up(lg) {
			bottom: rem-calc(-45);
		}
	}

	img {
		width: 100%;
	}
}
