.ursa-campaign-landing {
	background: map-get($lfi-palette, sleet);
	text-transform: uppercase;

	p,
	h2,
	h3 {
		letter-spacing: 0.18em;
		line-height: 1.39;
	}
}

.ursa-info-graphic-container {
	margin: 0 auto;
	max-width: $global-width;
}

.ursa-info-graphic-wrapper {
	display: grid;
	padding-bottom: rem-calc(64);
	padding-top: rem-calc(40);
	position: relative;

	@include media-breakpoint-up(md) {
		padding: rem-calc(80 40);
	}

	@include media-breakpoint-up(lg) {
		padding: rem-calc(80 108);
	}

	@include media-breakpoint-up(xl) {
		padding: rem-calc(80 0);
	}

	.info-callout {
		border-left: rem-calc(2) solid map-get($lacrosse-palette, ursa-orange);
		margin-bottom: rem-calc(40);
		margin-left: auto;
		margin-right: rem-calc(40);
		max-width: rem-calc(213);
		order: 1;
		padding: rem-calc(11 0 11 16);

		@include media-breakpoint-up(md) {
			bottom: 0;
			padding-bottom: 0;
			padding-top: 0;
			position: absolute;
			right: 0;
		}

		@include breakpoint(tablet) {
			bottom: rem-calc(160);
		}

		@include media-breakpoint-up(xl) {
			bottom: rem-calc(210);
			right: rem-calc(20);
		}

		p {
			font-size: rem-calc(14);
			font-weight: $font-weight__regular;
		}
	}

	.info-image {
		display: flex;
		justify-content: center;
		margin-bottom: rem-calc(50);
		order: 2;

		@include breakpoint(tablet) {
			margin-bottom: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: rem-calc(-100);
		}

		img {
			max-width: rem-calc(343);

			@include breakpoint(tablet) {
				max-width: rem-calc(800);
			}

			@include media-breakpoint-up(lg) {
				max-width: 100%;
			}
		}
	}

	.info-text {
		order: 3;
		padding: rem-calc(0 24);

		@include media-breakpoint-up(md) {
			order: 1;
			padding: 0;
		}
	}

	.tablet {
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}

	.mobile {
		@include breakpoint(tablet) {
			display: none;
		}
	}

	h2 {
		font-family: $body-font-family;
		font-size: rem-calc(14);
		letter-spacing: 0.18em;
		line-height: 1.12;
		margin-bottom: rem-calc(24);
		text-transform: none;

		@include media-breakpoint-up(md) {
			font-size: rem-calc(16);
			line-height: 1.2;
			max-width: rem-calc(329);
		}
	}

	h3 {
		font-family: $body-font-bold;
		font-size: rem-calc(16);
		letter-spacing: 0.27em;

		@include media-breakpoint-up(md) {
			font-size: rem-calc(20);
			letter-spacing: 0.18em;
			max-width: rem-calc(485);
		}
	}
}
