@import "../../default/styles/components/global/_icons.scss";

.icon-menu {
	&:before {
		mask-image: url('../icons/icon-menu.svg');
	}
}

.icon-up-down {
	&:before {
		mask-image: url('../icons/icon-up-down.svg');
	}
}

.icon-long-arrow {
	&:before {
		mask-image: url('../icons/icon-long-arrow.svg');
		width: 4em;
	}
}
