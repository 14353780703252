.header-banner {
	.banner-background {
		text-align: center;
		background-size: cover;
		background-position: center center;
		color: $white;
		height: rem-calc(400);

		@include breakpoint(medium) {
			height: rem-calc(460);
		}

		.lfi-productfamily-index-view & {
			@include breakpoint(medium) {
				height: rem-calc(600);
			}
		}

		.banner-content-wrapper {
			@include xy-grid-container();
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.banner-content {
			font-size: rem-calc(21);

			h1 {
				color: $white;
				text-transform: uppercase;

				.lfi-productfamily-index-view & {
					font-size: rem-calc(32);

					@include breakpoint(medium) {
						font-size: rem-calc(48);
					}
				}
			}

			p {
				font-size: rem-calc(18);

				.lfi-productfamily-index-view & {
					font-size: rem-calc(21);
					line-height: 1.5;

					@include breakpoint(medium) {
						line-height: 1.6;
					}
				}
			}
		}
	}
}
