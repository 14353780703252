.cms-about-lacrosse {
	.page-main {
		margin: 0;
	}

	h1 {
		text-transform: uppercase;
	}
}

.about-page-hero {
	.play-video {
		a::before {
			font-size: rem-calc(45);
		}
	}

	// todo - make global and find an alternative to keep with accessibility standards
	a:focus {
		outline: none;
	}

	.banner-content {
		@include breakpoint(small only) {
			margin-bottom: rem-calc(220);
		}

		img {
			margin-bottom: rem-calc(25);
			max-width: rem-calc(275);

			@include breakpoint(medium) {
				padding: rem-calc(0 30);
				max-width: rem-calc(690);
			}
		}
	}

	.banner-background {
		height: rem-calc(732);
		background-repeat: no-repeat;
	}
}

//
// CMS About Page Callout Content
// --------------
// section.about-callout
//  div.about-callout-wrapper
//   h1
//   div.about-callout-content
//    div.col
//    div.col

.about-callout {
	@include adjust-padding(
		$medium: rem-calc(0 80 0 80),
		$large: rem-calc(0 243 0 243)
	);
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: none;
	justify-content: center;
	min-height: rem-calc(800);
	position: relative;

	@include breakpoint(medium) {
		display: flex;
	}

	&::before {
		background: linear-gradient(180deg, #0000 18.75%, #00000075 49.48%);
		content: '';
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
	}
}

.about-callout-wrapper {
	color: $white;
	margin: 0 auto;
	max-width: rem-calc(950);
	text-shadow: 0 0 rem-calc(3) $black;
	z-index: 1;

	h1 {
		margin-bottom: rem-calc(39);
		text-align: center;
	}
}

.about-callout-content {
	display: flex;

	.col {
		flex: 1 1 50%;

		&:first-of-type {
			margin-right: rem-calc(20);
		}
	}
}

// CMS About Page Main Content
// --------------
// section.about-main
//  div.about-main-wrapper
//   h1
//   img.about-main-image
//   div.about-main-content
//    div.col
//    div.col

.about-main {
	@include adjust-padding(0, rem-calc(0 40), rem-calc(0 80), rem-calc(0 122));
	margin-bottom: rem-calc(60);

	h1 {
		margin-bottom: rem-calc(45);
		text-align: center;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(50);
		}
	}
}

.about-main-wrapper {
	margin: 0 auto;
	max-width: $global-width;
}

.about-main-image {
	display: block;
	margin: 0 auto rem-calc(42) auto;
	max-width: $global-width;
	width: 100%;

	@include breakpoint(medium) {
		border-radius: rem-calc(10);
	}

	@include breakpoint(large) {
		margin-bottom: rem-calc(50);
	}
}

.about-main-content {
	margin: rem-calc(0 40);
	max-width: $global-width;

	@include breakpoint(medium) {
		display: flex;
		margin: 0 auto;
	}

	.col {
		@include breakpoint(medium) {
			width: 40%;
		}
	}

	.col:first-of-type {
		margin-bottom: rem-calc(40);

		@include breakpoint(medium) {
			margin-bottom: 0;
			margin-right: rem-calc(50);
			max-width: rem-calc(675);
			width: 60%;
		}
	}

	.button {
		margin-bottom: rem-calc(30);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(45);
		}
	}
}

.partner-logos {
	display: flex;
	justify-content: space-evenly;

	img:not(:last-of-type) {
		margin-right: rem-calc(5);
	}

	div {
		align-items: center;
		display: flex;
		margin-right: rem-calc(7);

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.nwtf-logo {
	width: rem-calc(77);

	@include breakpoint(medium) {
		width: rem-calc(119);
	}
}

.ffa-logo {
	width: rem-calc(66);

	@include breakpoint(medium) {
		width: rem-calc(102);
	}
}

.waterfowl-logo {
	width: rem-calc(116);

	@include breakpoint(medium) {
		width: rem-calc(179);
	}
}

.slg-logo {
	width: rem-calc(100);

	@include breakpoint(medium) {
		width: rem-calc(140);
	}
}
