@import "../../default/styles/components/plp/_grid";

.page-products {
	background: map-get($lfi-palette, snow);
}

// Product Family Header
.group-by-info {
	p {
		color: map-get($lacrosse-palette, jet);
		font-size: rem-calc(20);
	}
}

.product-grid-header {
	@extend .h2;
	color: map-get($lfi-palette, jet);
}
