footer.social {
	background: map-get($lfi-palette, snow);
	padding-bottom: rem-calc(90);
	text-align: center;

	.footer-need-help-container {
		@include xy-grid-container();
		margin: 0 auto;
		padding-top: rem-calc(90);

		h2 {
			@extend h3;
		}

		p {
			margin: rem-calc(9 0);

			&.order-tools {
				margin: rem-calc(21 0);
			}
		}
	}
}

footer.contact {
	background: $white;
	padding-bottom: rem-calc(30);
	padding-top: rem-calc(50);

	ul.links {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		text-align: center;

		li {
			display: inline;
			float: none;
			margin: 0;

			&:not(:last-of-type)::after {
				content: "|";
				padding: rem-calc(6);
			}

			a {
				padding: rem-calc(3 6);
			}
		}

		a {
			color: $body-font-color;
			display: inline;

			&.store-switcher .flag::after {
				margin-left: 5px;
			}
		}
	}

	.copyright {
		padding-top: rem-calc(20);
	}
}

footer .newsletter,
.form-subscribe {
	@include xy-grid-container(100%);

	align-items: center;
	background: url('../images/newsletter-subscribe-bg.jpg') no-repeat center center;
	background-size: cover;
	color: $white;
	display: flex;
	flex-wrap: wrap;
	height: rem-calc(350);
	justify-content: center;
	text-align: center;

	@include breakpoint(retina) {
		background-image: url('../images/newsletter-subscribe-bg@2x.jpg');
	}

	p {
		font-size: rem-calc(21);
		font-weight: $font-weight__light;
		margin: rem-calc(9 0 24);
	}

	.form-contents {
		.button {
			margin-bottom: 0;
		}
	}

	.form-subscribe-header {
		margin-bottom: rem-calc(15);
	}
}
