/**
 * Global Styles
 */

$aerohead-font: "Sucrose Bold", "stratum-1-web", Helvetica, sans-serif;

.aerohead-sport-mark {
	h2 {
		margin-bottom: 0;
		font-size: rem-calc(20);
		text-transform: none;

		@include breakpoint(large) {
			font-size: rem-calc(42);
		}
	}

	i {
		vertical-align: super;
		font-style: normal;
		font-size: rem-calc(10);

		@include breakpoint(large) {
			font-size: rem-calc(20);
		}
	}

	h4 {
		position: relative;
		font-size: rem-calc(10);
		text-transform: uppercase;
		letter-spacing: rem-calc(1);

		&:before {
			content: "";
			position: relative;
			display: inline-block;
			top: rem-calc(-3);
			left: rem-calc(-7);
			width: rem-calc(15);
			height: rem-calc(1);
			background-color: map-get($lacrosse-palette, gold);

			@include breakpoint(large) {
				top: rem-calc(-6);
				left: rem-calc(-15);
				width: rem-calc(35);
				height: rem-calc(2);
			}
		}

		&:after {
			content: "";
			position: relative;
			display: inline-block;
			top: rem-calc(-3);
			right: rem-calc(-7);
			width: rem-calc(15);
			height: rem-calc(1);
			background-color: map-get($lacrosse-palette, gold);

			@include breakpoint(large) {
				top: rem-calc(-6);
				right: rem-calc(-15);
				width: rem-calc(35);
				height: rem-calc(2);
			}
		}

		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}
}

/**
 * Homepage Updates
 */

.hero-banner.aerohead-sport-campaign {
	height: rem-calc(350);

	@include breakpoint(medium) {
		height: initial;
	}

	.hero-content {
		margin-top: 15%;
		color: $white;

		a {
			color: inherit;
		}

		> h1 {
			font-family: $aerohead-font;
			font-size: rem-calc(60);

			@include breakpoint(large) {
				font-size: rem-calc(120);
			}
		}

		> h3 {
			margin-top: 5%;
			margin-bottom: 0;
			font-size: rem-calc(15);
			color: inherit;

			@include breakpoint(large) {
				font-size: rem-calc(30);
			}
		}

		.aerohead-sport-mark {
			margin-top: 15%;
		}
	}
}

.campaign-intro.aerohead-sport-campaign {
	background-color: $black;
	color: $white;

	p {
		margin: rem-calc(44) auto;
		line-height: 1.8;
		font-size: rem-calc(18);
		text-align: center;
		text-transform: uppercase;

		@include breakpoint(large) {
			margin-top: rem-calc(120);
			margin-bottom: rem-calc(120);
			font-size: rem-calc(25);
		}
	}
}

/**
 * Family Landing Updates
 */

.header-banner.aerohead-sport-campaign {
	.banner-content {
		> h1 {
			font-family: $aerohead-font;
			font-size: rem-calc(
				60
			) !important; // required to override the standard banner-content h1

			@include breakpoint(large) {
				font-size: rem-calc(120) !important;
			}
		}
	}
}

.family-callout-stack {
	.intro {
		background-color: $black;
		color: $white;
		font-size: rem-calc(15);
		font-weight: $font-weight__bold;
		text-align: center;
		text-transform: uppercase;

		p {
			padding-top: rem-calc(35);
			padding-bottom: rem-calc(35);
			line-height: 1.8;
		}

		@include breakpoint(large) {
			font-size: rem-calc(25);

			p {
				padding-top: rem-calc(50);
				padding-bottom: rem-calc(50);
				line-height: 2;
			}
		}
	}

	ul {
		margin-left: 0;
		list-style-type: none;

		> li {
			height: rem-calc(354);
			margin-bottom: 0;
			color: $white;
			font-size: rem-calc(15);

			@include breakpoint(large) {
				height: rem-calc(606);
				font-size: rem-calc(22);
			}
		}

		.cell {
			display: flex;
			align-items: center;
			text-align: center;
		}

		h3 {
			margin-bottom: rem-calc(40);
			line-height: 90%;
			font-family: $aerohead-font;
			font-size: rem-calc(64);

			@include breakpoint(medium down) {
				margin-bottom: rem-calc(20);
				font-size: rem-calc(35);

				br {
					display: none;
				}
			}
		}
	}

	.family-callout {
		padding-left: rem-calc(50);
		padding-right: rem-calc(50);

		> p {
			display: inline-flex;
			max-width: 75%;

			@include breakpoint(medium down) {
				line-height: 1.3;
			}
		}
	}
}
