//
// Social Link Structure
// ---------------------
// ul.social-links
//  li
//   a
//    i.icon-facebook

.social-links {
	a {
		@include icon-circle;
	}

	[class^="icon-"],
	[class*=" icon-"] {
		&::before {
			vertical-align: bottom;
		}
	}

	li:not(:last-of-type) {
		margin-right: rem-calc(12);
	}
}
