@import "../../default/styles/components/pdp/crosssell";

.crosssell-shop-category {
	color: map-get($lfi-palette, jet);
}

.quick-add-item {
	a {
		color: map-get($lfi-palette, jet);
	}
}
