/*
 * body.page-layout-ambassador-landing
 */

.page-layout-ambassador-landing {
	.banner-content {
		font-weight: $font-weight__light;
		line-height: rem-calc(24);

		h1 {
			font-size: rem-calc(30);

			@include breakpoint(medium) {
				font-size: rem-calc(48);
			}
		}
	}

	.ambassador-top-wrapper {
		padding-top: rem-calc(68);

		@include breakpoint(medium) {
			padding-top: rem-calc(85);
		}
	}
}

.ambassadors-list,
.ambassador-organizations {
	@include xy-grid-container(100%, 20px);
	display: flex;
	flex-flow: row wrap;

	@include breakpoint(medium) {
		@include xy-grid-container(100%, 40px);
		justify-content: center;
	}
}

.ambassadors-container {
	margin: 0 auto;
	max-width: rem-calc(1200);

	h3 {
		@include headline-strike();
		font-size: rem-calc(24); // No responsive header sizes
		margin-bottom: rem-calc(43);
		padding: 0 $global-padding;

		@include breakpoint(medium) {
			padding: rem-calc(0 25);
			margin-bottom: rem-calc(63);
		}
	}

	.category-cms {
		margin-bottom: rem-calc(45);
	}

	.ambassador-social-links {
		display: none;
	}

	.ambassador-list-item {
		@include xy-cell(1 of 2, $gutters: 30px);
		position: relative;
		margin-bottom: rem-calc(50);
		text-align: center;

		@include breakpoint(medium) {
			@include xy-cell(1 of 4, $gutters: 30px);
		}

		@include breakpoint(large) {
			@include xy-cell(1 of 6, $gutters: 30px);
		}

		img {
			margin-bottom: rem-calc(15);
			width: 100%;
		}

		a {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
		}

		> p {
			margin: 0; // Visual editor sometimes adds <p> tags if the merchandiser isn't careful
		}

		p {
			color: map-get($lfi-palette, metal);
			font-weight: $font-weight__lighter;
			text-align: center;

			&:first-of-type {
				color: map-get($lfi-palette, jet);
				font-weight: $font-weight__bold;
			}
		}
	}
}

.ambassador,
.ambassador-actions {
	display: none;

	&.visible {
		display: inline-block;
	}
}

.ambassador-organizations-wrapper {
	h3 {
		margin-bottom: rem-calc(73);
	}
}

.ambassador-actions {
	margin-bottom: rem-calc(55);
	text-align: center;

	@include breakpoint(medium) {
		margin: rem-calc(20 0 75 0);
	}

	&.visible {
		display: block;
	}

	.button {
		min-width: rem-calc(180);
		min-height: rem-calc(46);
	}
}
