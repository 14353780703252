@import "../../default/styles/components/pdp/_feature-callouts.scss";

.features-callout-container {
	padding-left: 15%;
	padding-right: 15%;
	text-align: center;

	dd {
		color: map-get($lfi-palette, cream);
		line-height: 1.6;
	}
}
