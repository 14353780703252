@mixin headline-strike(
	$width: 2px,
	$color: map-get($lfi-palette, latte),
	$align: center
) {
	display: flex;
	align-items: center;
	justify-content: center;

	&::before,
	&::after {
		border-top: $width solid $color;
		content: "";
		flex: 1;
	}

	$spacing: 9px;
	&::before {
		margin-right: $spacing;
	}
	&::after {
		margin-left: $spacing;
	}

	@if $align == left {
		justify-content: flex-start;

		&::before {
			display: none;
		}
	}
}
