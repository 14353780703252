//
.campaign-static-cta {
	.container {
		position: relative;
	}

	.wrapper {
		align-items: center;
		background: $black;
		display: flex;
		min-width: rem-calc(260);
		position: absolute;
		right: 0;

		@include media-breakpoint-up(md) {
			min-width: rem-calc(306);
		}
	}

	p {
		color: $white;
		font-family: $header-font-family;
		font-weight: $font-weight__bold;
		padding: rem-calc(16 12 16 24);
		text-transform: uppercase;
	}

	a {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 2;
	}

	span {
		border: rem-calc(1) solid map-get($lacrosse-palette, ursa-orange);
		flex: 1 1 auto;
		height: rem-calc(1);
		margin-right: rem-calc(20);
		position: relative;

		&:after {
			background: map-get($lacrosse-palette, ursa-orange);
			border-radius: rem-calc(50);
			content: '';
			display: inline-block;
			height: rem-calc(5);
			position: absolute;
			right: rem-calc(-2.5);
			top: rem-calc(-2.5);
			width: rem-calc(5);
		}
	}
}

/////////
// Videos
/////////
.campaign-video-info-container,
.campaign-video-hero-container {
	line-height: 0;

	video {
		width: 100%;
	}
}

//////
// Image and Text Block
//////

.campaign-image-and-text-container {
	background: map-get($lfi-palette, sleet);
	padding: rem-calc(64 24);

	@include media-breakpoint-up(lg) {
		padding: rem-calc(104 108);
	}
}

.campaign-image-and-text-wrapper {
	margin: 0 auto;
	max-width: $global-width;

	.image-block {
		margin-bottom: rem-calc(40);

		@include media-breakpoint-up(md) {
			margin-bottom: rem-calc(80);
		}
	}

	.text-block {
		position: relative;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			display: flex;
		}
	}

	h2 {
		font-family: $body-font-bold;
		font-size: rem-calc(37);
		letter-spacing: 0.21em;
		line-height: 1.18;
		margin-bottom: rem-calc(24);
		max-width: rem-calc(200);
		order: 2;

		@include media-breakpoint-up(md) {
			font-size: rem-calc(53);
			letter-spacing: 0.18em;
			line-height: 1.25;
			margin-bottom: 0;
			max-width: none;
		}
	}

	p {
		font-size: rem-calc(14);

		@include media-breakpoint-up(md) {
			font-size: rem-calc(20);
			margin-right: rem-calc(50);
			max-width: rem-calc(475);
		}

		@include media-breakpoint-up(xl) {
			margin-right: rem-calc(88);
			max-width: rem-calc(575);
		}
	}

	span {
		border-bottom: rem-calc(2) solid map-get($lacrosse-palette, ursa-orange);
	}

	img {
		width: 100%;
	}
}

////////
// Double Image and Text Block
///////

.campaign-double-image-and-text-container {
	background: map-get($lfi-palette, sleet);
	padding: rem-calc(64 24 0 24);

	@include media-breakpoint-up(lg) {
		padding: rem-calc(104 108 0 108);
	}
}

.campaign-double-image-and-text-wrapper {
	margin: 0 auto;
	max-width: $global-width;

	.double-image-container {
		display: grid;

		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.image-block-1 {
		@include media-breakpoint-up(md) {
			order: 2;
		}
	}

	.image-block-2 {
		@include media-breakpoint-up(md) {
			order: 3;
		}
	}

	.text-block-1,
	.text-block-2 {
		width: 100%;

		@include media-breakpoint-up(md) {
			align-items: center;
			display: flex;
			padding: rem-calc(0 64);
		}
	}

	.text-block-1 {
		padding: rem-calc(88 0);

		@include media-breakpoint-up(md) {
			order: 1;
			padding: rem-calc(0 64);
		}
	}

	.text-block-2 {
		padding: rem-calc(40 0 12 0);

		@include media-breakpoint-up(md) {
			order: 4;
			padding: rem-calc(40 0 0 40);
		}

		@include media-breakpoint-up(xl) {
			padding: rem-calc(0 44 0 64);
		}
	}

	.text-container {
		width: 100%;
	}

	ul {
		@include list-reset;
	}

	li {
		border-bottom: rem-calc(1) solid $body-font-color;
		display: flex;
		justify-content: space-between;
		margin-bottom: rem-calc(16);
		padding-bottom: rem-calc(4);

		&:last-of-type {
			margin-bottom: 0;
		}

		span:first-of-type {
			font-family: $body-font-bold;
		}
	}

	span {
		letter-spacing: 0.18em;
		line-height: 1.39;
		text-align: right;
	}

	h2 {
		font-family: $body-font-bold;
		font-size: rem-calc(16);
		margin-bottom: rem-calc(64);

		@include media-breakpoint-up(md) {
			font-size: rem-calc(32);
			margin-bottom: rem-calc(80);
		}
	}

	h3 {
		font-family: $body-font-bold;
		font-size: rem-calc(37);
		letter-spacing: 0.21em;
		line-height: 1.18;
		margin-bottom: rem-calc(24);

		@include media-breakpoint-up(xl) {
			font-size: rem-calc(53);
			letter-spacing: 0.18em;
			line-height: 1.36;
			margin-bottom: rem-calc(32);
		}
	}

	p {
		font-size: rem-calc(14);
		margin-bottom: rem-calc(12);

		@include media-breakpoint-up(xl) {
			font-size: rem-calc(20);
			margin-bottom: 0;
		}
	}

	img {
		width: 100%;
	}
}

/////////////
// Footer Image
////////////

.campaign-footer-image-container {
	background: map-get($lfi-palette, sleet);
	padding: rem-calc(64 24 64 24);

	@include media-breakpoint-up(lg) {
		padding: rem-calc(104 108 104 108);
	}

	img {
		width: 100%;
	}
}

.campaign-footer-image-wrapper {
	margin: 0 auto;
	max-width: $global-width;
}
