@import "../../default/styles/components/pdp/_shopbox.scss";

.product-info-top {
	.subtitle {
		font-family: $body-font-family-medium;
	}
}

.product-info-main {
	.product-info-price {
		font-family: $body-font-bold;
	}
}
