@import "../../../default/styles/components/menu";

.catalog-menu__list > li {
	> a {
		font-family: $header-font-family;
		font-weight: $font-weight__bold;

		@include breakpoint(tablet) {
			color: $white;
		}
	}

	&.secondary-link,
	&.bottom {
		a {
			font-weight: $font-weight__regular;
		}
	}
}

.catalog-menu__item.top > a {
	@include breakpoint(medium down) {
		border-bottom: rem-calc(1) solid $menu-item-inner-border-color;
		padding: rem-calc(20 0);
		text-transform: uppercase;
	}
}

.catalog-menu__inner-item--level1:not(.secondary-link) > a {
	font-family: $body-font-bold;
}

li.catalog-menu__inner-item--parent > a {
	text-transform: uppercase;
}

.catalog-menu__inner-item--parent-link a {
	font-weight: $font-weight__bold;
}

@include breakpoint(medium) {
	.catalog-menu__item--parent.catalog-menu-active > a {
		color: $accent-color;
	}
}
