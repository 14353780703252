.about-row {
	padding: rem-calc(60 0);
}

.brand-intro {
	background: map-get($lfi-palette, latte);
	padding: rem-calc(60 0);

	p {
		@include small-centered();
		background: transparent url(../images/signature.png) no-repeat center bottom;
		background-size: 20%;
		line-height: 1.5;
		padding-bottom: rem-calc(66);
	}
}

.hero-banner {
	.hero-content {
		h1 {
			font-size: rem-calc(30);

			@include breakpoint(medium) {
				font-size: rem-calc(54);
			}

			@include breakpoint(large) {
				font-size: rem-calc(64);
			}
		}
	}
}
