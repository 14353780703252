/*
 * div.ambassadors-list || div.ambassador-organizations
 *  div.ambassador-list-item
 *   a
 *   div.ambassador-details
 *    img
 *    div
 *     p
 *     p
 *   div.ambassador-social-links
 */

.ambassador-details {
	p {
		font-weight: $font-weight__lighter;
		line-height: rem-calc(24);
		margin: 0;
		text-align: center;

		&:first-of-type {
			font-weight: $font-weight__bold;
		}

		&:last-of-type {
			line-height: rem-calc(20);
		}
	}

	img {
		border-radius: 50%;
	}
}

/*
 * div.ambassador-top-wrapper
 *  div.category-description
 */

.ambassador-top-wrapper {
	margin-bottom: rem-calc(75);
	padding: rem-calc(0 20);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(95);
	}

	.category-description {
		color: map-get($lfi-palette, jet);
		font-weight: $font-weight__light;
		line-height: rem-calc(26);
		margin: 0 auto;
		max-width: rem-calc(660);
		text-align: center;
	}
}

/*
 * div.ambassador-instagram
 *  p
 *   a
 */

.ambassador-social-links {
	font-weight: $font-weight__light;

	p {
		line-height: rem-calc(26);
		margin-bottom: 0;
	}

	a {
		font-weight: $font-weight__regular;
	}
}
