@import "../../default/styles/components/checkout/_cart.scss";

.checkout-cart-index {
	.product-name {
		font-family: $body-font-bold;
	}
}

.cart-form-title {
	text-transform: uppercase;
}

.cart-summary {
	.summary {
		text-transform: uppercase;
	}
}
