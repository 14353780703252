@import "../../default/styles/_elements.scss";

///
// Forms
input:disabled,
select:disabled {
	background: map-get($lfi-palette, snow);
	border-color: map-get($lfi-palette, snow);
	color: $light-gray;

	&::placeholder {
		color: $light-gray;
	}

	// Checkbox/Radio Labels
	~ label {
		color: $light-gray;
	}
}

// todo - figure out what is up with $global-letter-spacing
body {
	letter-spacing: rem-calc(0.5);
}
