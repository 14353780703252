@import "../../../default/styles/globals/fonts";

@font-face {
	font-family: "Sucrose Bold";
	font-style: normal;
	font-weight: normal;
	src:
		url("../fonts/Sucrose-Bold.otf") format("opentype"),
		url("../fonts/Sucrose-Bold.woff") format("woff");
}

$header-font-family: "stratum-1-web", "Helvetica Neue", helvetica, roboto, arial,
	sans-serif;

$body-font-family: "Opinion W05 Regular", "Helvetica Neue", helvetica, roboto,
	arial, sans-serif;
$body-font-family-medium: "Opinion W05 Medium", "Helvetica Neue", helvetica,
	roboto, arial, sans-serif;
$body-font-bold: "Opinion W05 Bold", "Helvetica Neue", helvetica, roboto, arial,
	sans-serif;
$body-font-condensed-bold: "Opinion Pro Condensed W05 Bold", "Helvetica Neue",
	helvetica, roboto, arial, sans-serif;
$header-link-context-font: $body-font-family;
