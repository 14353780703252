//
// About Page Slider
// -----------------
// section.about-slider
//  div.about-slider-container
//   div.slick-list
//    div.slick-track
//     div.about-slide
//      div.about-slide-image
//       img
//      div.about-slide-content
//       div.about-slide-title
//        h1
//        hr
//       p

$slider-large-padding-left: rem-calc(243);

.about-slider {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	margin-bottom: rem-calc(50);

	@include breakpoint(large) {
		padding-left: $slider-large-padding-left;
	}

	.icon-long-arrow {
		bottom: rem-calc(60);
		cursor: pointer;
		position: absolute;

		@include breakpoint(large) {
			bottom: rem-calc(110);
		}

		&.slick-disabled {
			opacity: 0.2;
		}
	}

	.slick-arrow-prev {
		left: rem-calc(38);
		transform: rotateY(180deg);

		@include breakpoint(large) {
			left: 0;
		}
	}

	.slick-arrow-next {
		left: rem-calc(120);

		@include breakpoint(large) {
			left: rem-calc(80);
		}
	}

	// hr overflows right so we want to show current and next
	.slick-current,
	.slick-current + .slick-slide {
		hr {
			visibility: visible;
		}
	}
}

.about-slider-container {
	margin-top: rem-calc(-65); // extra 10 so image can slightly rotate
	padding-bottom: rem-calc(130);
	position: relative;

	@include breakpoint(large) {
		margin-top: rem-calc(-140); // extra 10 so image can slightly rotate
		margin-left: calc(
			((100vw - #{rem-calc(950)}) / 2) - #{$slider-large-padding-left}
		); // some math to anchor right
		padding-bottom: rem-calc(180);
	}

	.slick-arrow {
		&:before {
			color: map-get($lfi-palette, metal);
		}
	}
}

.about-slide {
	padding: rem-calc(10 38 0 38); // and that extra 10 at the top
	width: 100vw;

	@include breakpoint(medium) {
		align-items: flex-start;
		display: flex !important; // override slick
	}

	@include breakpoint(large) {
		padding-left: rem-calc(5);
		width: rem-calc(950);
	}
}

.about-slide-image {
	background: $white;
	border-radius: rem-calc(15);
	box-shadow: rem-calc(0 4 10 0) rgba(0, 0, 0, 0.1);
	display: inline-block;
	margin-bottom: rem-calc(30);
	padding: rem-calc(9);
	transition: all 0.3s ease-in-out 0.3s;
	z-index: 2;

	@include breakpoint(large) {
		width: rem-calc(470);
	}

	.slick-current & {
		transform: rotate(-2deg);
	}

	img {
		border-radius: rem-calc(10);
		width: 100%;
	}
}

.about-slide-title {
	align-items: center;
	color: $white;
	display: flex;
	margin-bottom: rem-calc(15);
	position: relative;

	@include breakpoint(medium) {
		flex-flow: row wrap;
		margin-top: rem-calc(13);
		margin-bottom: rem-calc(20);
	}

	@include breakpoint(large) {
		margin-top: rem-calc(85);
	}

	h1,
	hr {
		margin: 0;
	}

	hr {
		border: rem-calc(2) dashed $white;
		flex: 1 1 auto;
		margin-right: rem-calc(-38);
		width: 100%;
		max-width: none;
		transition: visibility 0s ease 0.3s; // delay disappearance for perfection
		visibility: hidden; // don't want this ugly thing poking out

		@include breakpoint(medium) {
			bottom: 0;
			left: rem-calc(-30);
			position: absolute;
			width: 200%;
		}

		@include breakpoint(large) {
			left: rem-calc(-50);
		}
	}

	h1 {
		padding-right: rem-calc(15);

		@include breakpoint(medium) {
			border-left: rem-calc(2) solid $white;
			padding-left: rem-calc(12);
		}

		@include breakpoint(large) {
			padding-bottom: rem-calc(4);
		}
	}
}

.about-slide-content {
	flex: 1 0 50%;

	@include breakpoint(medium) {
		padding-left: rem-calc(30);
	}

	@include breakpoint(large) {
		padding-left: rem-calc(48);
	}
}
