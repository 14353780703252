.cms-ffa {
	// Global Overrides

	.row {
		max-width: none;
	}
	.columns {
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.page-main {
		color: $white;
		margin-bottom: 0;
		margin-top: 0;

		h1 {
			color: inherit;
			margin-top: rem-calc(30px);
		}
		h2 {
			color: inherit;
			font-size: rem-calc(24px);
			margin-bottom: rem-calc(15px);
			text-transform: uppercase;
		}
		h3 {
			color: inherit;
			font-size: rem-calc(18px);
			text-transform: uppercase;
		}
		p {
			font-size: rem-calc(18px);
			font-weight: 400;
		}
	}

	// Page Elements

	hr.medium {
		background-color: $dark-gray;
		border: none;
		color: $dark-gray;
		height: rem-calc(1px);
		margin: rem-calc(8px) auto;
		width: rem-calc(130px);
	}

	// Sections

	.hero-banner {
		align-items: flex-start;
		text-align: center;
		padding-top: rem-calc(30px);
		background-image: url("//s7d4.scene7.com/is/image/LaCrosse/lacrosse-feature-full-width/farm");

		@include breakpoint(large) {
			padding-top: rem-calc(60px);
		}

		.hero-content {
			vertical-align: inherit;

			p {
				margin: 0 auto;
			}
			.ffa-seal {
				margin: rem-calc(30px 0);
			}
		}
	}

	.blue-program {
		background-color: #c58c0b;
		color: $dark-gray;

		.jacket {
			padding: rem-calc(60px 0);
			background: radial-gradient(260px, #ffd420, #c58c0b);

			img {
				align-self: center;
			}
			h3 {
				flex-basis: 100%;
				font-size: rem-calc(18px);
				margin: 0 auto;
			}
		}

		.copy {
			padding: rem-calc(30px 30px 0 30px);

			@include breakpoint(large) {
				align-self: center;
				vertical-align: middle;
				padding-top: 0;
				background-size: contain;
			}

			.wrapper {
				padding-left: rem-calc(15px);
				padding-right: rem-calc(15px);
				text-align: center;

				p {
					margin-bottom: 0;
				}
			}
		}
	}

	.scholarships {
		background: #0a4a81
			url("//s7d4.scene7.com/is/image/LaCrosse/farmers-1?wid=960") no-repeat
			right;
		background-size: contain;

		.copy {
			background-color: #0a4a81;
			background: radial-gradient(250px, #02659c, #0a4a81);

			.wrapper {
				display: inline-block;
				vertical-align: middle;
				padding: rem-calc(30px);
				text-align: center;

				@include breakpoint(large) {
					padding-top: 25%;
					padding-bottom: 25%;
				}

				h3 {
					color: #fec608;
				}
				hr {
					color: #fec608;
					background-color: #fec608;
				}
				p {
					margin-left: auto;
					margin-right: auto;
					margin-bottom: rem-calc(20px);

					@include breakpoint(large) {
						width: 60%;
					}
				}
			}
		}
	}

	.mission {
		background-image: url("//s7d4.scene7.com/is/image/LaCrosse/farmers-2?wid=2400");
		background-size: cover;

		.copy {
			margin-bottom: 30%;
			padding-top: 5%;
			text-align: center;

			@include breakpoint(large) {
				margin-bottom: 35%;
				background-size: contain;
			}

			p {
				margin: 0 auto;
			}
		}
	}

	.call-to-action {
		background-color: $dark-gray;
		padding: 5% 0;
		text-align: center;

		.copy {
			@include breakpoint(large) {
				background: transparent
					url("//s7d4.scene7.com/is/image/LaCrosse/original-png/FFA-supporter")
					no-repeat;
				background-position: left rem-calc(30px) top;
				background-size: contain;
			}
		}

		.button {
			margin-top: rem-calc(20px);
			margin-bottom: 0;
			font-weight: bold;
		}
	}
}
