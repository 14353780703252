@import "../../default/styles/components/global/_email-modal.scss";

.email-modal-top {
	.subheader {
		color: map-get($lfi-palette, jet);
		font-size: rem-calc(27);
	}
}

.email-modal-bottom {
	@include input-group-color-adjustment();
}
