/*
 * Branded Statement Section
 * ---------------------------------------
 * section.brand-statement
 *  h1
 *  p
 */

.brand-statement {
	@include adjust-padding(
		rem-calc(41 38 96 38),
		rem-calc(60 120),
		rem-calc(117 245)
	);
	background: map-get($lfi-palette, stone);
	color: $white;
	position: relative;

	&::before {
		background-color: transparentize($black, 0.7);
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}

	h1 {
		margin: rem-calc(0 30 8 30);
	}
}

.brand-statement-wrapper {
	margin: 0 auto;
	max-width: rem-calc(950);
	position: relative;
	text-align: center;
	z-index: 11;
}

.brand-statement-background {
	background-color: transparentize(map-get($lacrosse-palette, grit), 0.5);
	height: 100%;
	left: 0;
	mask-image: url("../images/momentum-icon.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	position: absolute;
	top: 0;
	width: 100%;

	@include breakpoint(medium) {
		background-size: contain;
	}
}
