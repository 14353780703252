@import "../../default/styles/components/pdp/relations";

.meet-the-family,
.block-related,
.block-upsell {
	.product-item-name {
		font-family: $body-font-bold;
	}
}
.product-sells-heading {
	text-transform: uppercase;
}
