// Logo alternative according to brand handbook

.logo {
	fill: $primary-color;

	&.dark {
		polyline,
		path {
			fill: $black;
		}
	}
}
