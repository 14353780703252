/*
 * body.page-layout-ambassador
 */

.page-layout-ambassador {
	.banner-background .banner-content {
		display: none;
	}

	.category-cms {
		text-align: center;
		padding: rem-calc(0 0 30 0);
		margin: rem-calc(-218 0 0 0);

		> p {
			margin: 0; // Visual editor sometimes adds <p> tags if the merchandiser isn't careful
		}

		@include breakpoint(medium) {
			margin: rem-calc(-235 0 0 0);
		}
	}

	.ambassador-details {
		margin-bottom: rem-calc(100);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(139);
		}

		img {
			box-shadow: 0 0 0 3px $white;
			margin-bottom: rem-calc(20);
			width: rem-calc(120);
		}

		p {
			color: $white;
			font-size: rem-calc(18);

			&:first-of-type {
				font-size: rem-calc(24);
			}
		}
	}
}

.ambassador-products-container {
	padding: 0 $global-padding;
	margin: 0 auto;
	max-width: $global-width;

	.products-list {
		display: flex;
		padding-bottom: rem-calc(25);
		margin-bottom: rem-calc(70);
		overflow-x: auto;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(60);
		}

		tbody {
			margin: 0 auto;
			border: 0;
		}
	}

	.product {
		padding: rem-calc(0 4.5);
		max-width: rem-calc(168);
		min-width: rem-calc(168);
		position: relative;
		vertical-align: top;

		@include breakpoint(medium) {
			max-width: rem-calc(193);
			min-width: rem-calc(193);
			padding: rem-calc(0 5);
		}
	}

	.product-item-info {
		text-align: center;
	}

	.product-image {
		margin-bottom: rem-calc(8);

		@include breakpoint(medium) {
			padding: rem-calc(0 5);
		}
	}

	.product-item-link {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.product-name {
		color: map-get($lfi-palette, jet);
		font-weight: $font-weight__bold;
	}

	.product-descriptor {
		color: map-get($lfi-palette, stone);
		font-weight: $font-weight__light;
	}

	h3 {
		font-size: rem-calc(24);
		margin-bottom: rem-calc(45);
		text-align: center;
		text-transform: none;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(60);
		}
	}

	p {
		line-height: rem-calc(24);
		margin: 0;
	}
}
