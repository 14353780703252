@import "../../default/styles/components/pdp/_specifications.scss";

.product-specs--wrapper {
	.term {
		font-family: $body-font-bold;
	}

	@include breakpoint(medium) {
		padding-left: 15%;
		padding-right: 15%;
	}
}

.specification-subheader {
	text-transform: uppercase;
}

