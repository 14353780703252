@import "../../../default/styles/components/nav-evo";

:root {
	--lfi-nav-link-font-size: 18px;
	--lfi-nav-link-font-weight: inherit;

	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-color: #{$white};
	}
}

.offcanvas-nav-menu a {
	font-weight: var(--lfi-nav-link-font-weight);
}

.active > a {
	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-color: #{$accent-color};
	}
}

.offcanvas-nav .offcanvas-nav-icon-button {
	--lfi-nav-link-font-family: #{inspect($body-font-family)};
	--lfi-nav-link-line-height: 20px;
}

%offcanvas-nav-large-category-link {
	> a {
		--lfi-nav-link-font-family: #{inspect($header-font-family)};
		--lfi-nav-link-font-weight: 900;
		--lfi-nav-link-text-transform: uppercase;
	}
}

%offcanvas-top-link-tablet-styles {
	@include media-breakpoint-up(tablet) {
		--lfi-nav-link-font-weight: inherit;
		--lfi-nav-link-font-size: 16px;
	}
}

.offcanvas-nav-submenu {
	--lfi-nav-link-color: map-get($lfi-palette, jet);
}

a.offcanvas-nav-accordion-toggle {
	--lfi-nav-link-font-family: #{inspect($body-font-bold)};
}
