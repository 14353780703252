@import "../../default/styles/type";

///
// Theme-Specific Overrides

.subheader {
	font-weight: $font-weight__bolder;
}

strong {
	font-family: $body-font-bold;
	font-weight: $font-weight__regular;
}

p {
	letter-spacing: rem-calc(0.5);
}
